/**  antd全局样式 */

@import 'static/_variables.scss';

.ant-input[disabled],
.ant-select-disabled .ant-select-selection-item,
.ant-picker.ant-picker-disabled input[disabled] {
  color: rgba(0, 0, 0, 0.65);
}

.text-align-right {
  text-align: right !important;
}

.text-align-center {
  text-align: center !important;
}

.anticon-arrow-left,
.anticon-arrow-left svg {
  font-size: inherit;
}

.ant-message .ant-message-notice .ant-message-notice-content {
  border-radius: 2px;
  color: $PfffColor;
  background-color: $P218Color;
}

.ant-message .ant-message-notice .ant-message-notice-content .ant-message-custom-content {
  display: flex;
  align-items: center;
}

/* 滚动条整体样式（只针对WebKit浏览器） */
::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background: $P2cColor;
  border-radius: 3px;
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
  background: rgba(56, 137, 255, 0.2);
  border-radius: 3px;
}

/* 两个滚动条交接处 -- x轴和y轴 */
::-webkit-scrollbar-corner {
  background-color: transparent !important;
}

/* input */
.global-input {
  color: #fefefe;
  background: none !important;
  min-height: 30px;
  border: 1px solid rgba(56, 225, 255, 0.5);
  border-radius: 0%;

  &::placeholder {
    color: rgba(254, 254, 254, 0.4);
  }

  &.ant-input-focused,
  &.ant-input:focus,
  &.ant-input:hover {
    border-color: #4096ff;
  }

  &:hover {
    background: none;
  }

  &.ant-input[disabled] {
    color: #8791A4;
    background: rgba(56, 137, 255, 0.04) !important;
    border-color: rgba(6, 59, 165, 0.274);
  }

  &:-internal-autofill-selected {
    background: none !important;
    background-image: none !important;
    background-color: light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4)) !important;
    color: fieldtext !important;
    border-color: rgba(61, 127, 255, 0.2);
  }

  .ant-input {
    color: #fefefe;
    background: none;

    &::placeholder {
      color: rgba(254, 254, 254, 0.4);
    }
  }

  .ant-btn-link {
    color: rgba(61, 127, 255, 0.2);
  }

  &.ant-input-password {
    background: none;
  }
}

.datart-daping-popover {
  padding: 0px;

  .ant-popover-inner {
    background: none;
    padding: 0 !important;
  }

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner-content {
    padding: 0px !important;
  }

  .ant-popover-content {
    line-height: 0px;
  }
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-text-fill-color: #fff !important;
  background-color: transparent;
  background-image: none;
  box-shadow: 0 0 0px 1000px transparent inset !important;
  transition: background-color 50000s ease-in-out 0s;
}

.global-form {
  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
    background: none;

    &:hover {
      background: none;
    }
  }

  .ant-input-affix-wrapper {
    background: none;
  }

  label {
    color: $Pf08Color !important;
  }

  .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
    background: none;
  }
}

.global-success-btn {
  color: #fff;
  background: #3d7fff !important;
  border: none;
  border-radius: 2px;

  &:hover,
  &:active,
  &:focus {
    color: #fff !important;
    background: #3d7fff !important;
  }
}

.global-cancel-btn {
  color: $P2cColor;
  background: rgba(56, 137, 255, 0.1);
  border: 1px solid rgba(56, 225, 255, 0.1);
  border-radius: 2px;

  &:hover,
  &:active,
  &:focus {
    border-color: #2CD6F7 !important;
    color: #2CD6F7 !important;
    background: rgba(56, 137, 255, 0.1) !important;
  }
}

.global-modal {

  .ant-modal-close-x {
    position: absolute;
    top: -5px !important;
  }

  .ant-modal-content {
    padding: 0px !important;
    background: linear-gradient(89deg, rgba(14, 46, 75, 0.97) 0%, rgba(9, 21, 42, 0.97) 100%);
    border: 1px solid rgba(31, 82, 132, 1);
    border-radius: 0px;
    /* backdrop-filter: blur(10px); */

    .ant-modal-header {
      padding: 15px 20px !important;
      border-bottom: 1px solid rgba(31, 82, 132, 1);
    }

    .ant-modal-body {
      padding: 15px 20px !important;
    }

    .ant-modal-footer {
      padding: 0px 20px 20px 20px !important;
    }

    .title-content {
      display: flex;
      color: $P2cColor;
      font-size: 16px;

      .title-return {
        display: flex;
        margin-right: 10px;
        cursor: pointer;

        &:hover,
        &:active,
        &:focus {
          color: $P2c08Color;
        }

        .title-return-logo {
          width: 20px;
          height: 20px;
          text-align: center;
          line-height: 15px;
          background-color: rgba(44, 214, 247, 0.4);
          border: 1px solid $P2c08Color;
          margin-right: 8px;
        }
      }

      .title-detail {
        span {
          color: $P2c08Color;
        }
      }

      .ant-modal-header {
        margin-bottom: 30px !important;
      }
    }

    .ant-modal-close {
      top: 10px;
      right: 10px;
    }

    .ant-modal-close-x {
      width: 48px;
      height: 48px;
      font-size: 16px;
      font-style: normal;
      line-height: 48px;
      color: $Pf08Color;

      :hover {
        color: $P2cColor;
      }
    }

    .ant-btn-primary {
      background: $P38fColor;
    }

    .ant-modal-body {
      padding: 0;

      .ant-modal-confirm-btns {
        display: none;
      }
    }

    .ant-modal-header {
      background: none;
      // border-image: linear-gradient(90deg, rgba(0, 132, 255, 0.34), rgba(2, 15, 47, 1)) 1 1;

      .ant-modal-title {
        color: #fff;
      }
    }

    .ant-modal-footer {
      border-image: linear-gradient(90deg, rgba(0, 132, 255, 0.34), rgba(2, 15, 47, 1)) 1 1;
    }
  }

}

.global-tabs {
  height: 100%;

  .ant-tabs {
    height: 50px;
    color: $P2cColor;
    border-bottom: 1px solid;
    border-image: linear-gradient(90deg, rgba(31, 199, 255, 0.377) 0%, rgba(2, 15, 47, 0) 100%) 1 1;

    .ant-tabs-tab {
      color: $PfffColor;
    }

    .ant-tabs-tab-btn:active,
    .ant-tabs-tab-btn:focus,
    .ant-tabs-tab-remove:active,
    .ant-tabs-tab-remove:focus {
      color: $P2cColor;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $P2cColor;
    }

    .ant-tabs-tab:hover {
      color: $P2cColor;
    }

    .ant-tabs-nav::before {
      border-color: rgba(0, 0, 0, 0);
    }

    .ant-tabs-ink-bar {
      background: $P2cColor;
    }

    .ant-tabs-nav .ant-tabs-tab {
      height: 50px;
      padding: 14px 0 8px 0;
    }
  }
}

.global-tabs-card {
  height: 30px;

  .ant-tabs-card {
    border: 0px !important;
  }

  .ant-tabs-tab {
    height: 30px !important;
    padding: 0 10px !important;
    border-radius: 0 !important;
    margin: 0 !important;
    color: $Pf08Color !important;
    border: 1px solid rgba(44, 214, 247, 0.2) !important;
  }

  .ant-tabs-tab-active {
    color: $PfffColor !important;
    background-color: rgb(25, 107, 136) !important;
    border-color: rgba(44, 214, 247, 0.2) !important;
  }
}

.global-search {
  background: transparent;
  border: 1px solid rgba(56, 225, 255, 0.5);
  border-radius: 2px;

  input,
  span {
    height: 30px;
    color: #fefefe !important;
    background: transparent;
    border: none;
  }

  .ant-input-group-addon {
    >button {
      color: #f5f5f5 !important;
      background: transparent;
      border: none;

      &:hover,
      &:active,
      &:focus {
        color: #f5f5f5 !important;
        background: transparent !important;
        border: none;
      }
    }
  }

  .ant-input {

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
    }

    &::placeholder {
      color: $INPUT_PLACEHOLDER;
    }
  }

}

.global-table {
  .ant-table {
    // overflow-y: auto;
    color: $Pf08Color;
    background: transparent;

    thead .ant-table-cell {
      color: rgb(255, 255, 255, 0.95);
      background: rgba(17, 70, 113, 1);
      border-bottom: none;
      padding: 10px;

      &.ant-table-cell-scrollbar {
        box-shadow: none;
      }
    }

    .ant-table-cell::before {
      width: 0px !important;
    }

    .ant-table-row.warning {
      color: $Pf1Color;
    }

    thead,
    tbody tr th span {
      font-weight: lighter;
      user-select: none;
    }

    tbody tr td {
      background: transparent;
      border-top: 1px solid transparent;
      border-bottom: 1px solid rgba(9, 67, 108, 1);
    }

    tbody td.ant-table-column-sort {
      background: transparent;
    }

    tbody .ant-table-cell-fix-left,
    .ant-table-cell-fix-right {
      background: transparent;
    }

    tbody .ant-table-cell {
      padding: 10px 10px;
    }

    .ant-table-tbody {
      background: transparent;

      .ant-table-placeholder {
        background-color: transparent;
      }

      .ant-empty-description {
        color: $Pf08Color;
      }

      tr.ant-table-row {

        &:hover,
        &:active,
        &:focus {
          td {
            //  font-weight: bold;
            background: #0a26427c;
            border-color: #15727E;
          }
        }
      }
    }
  }

  .ant-table.ant-table-empty {
    .ant-table-tbody>tr.ant-table-placeholder:hover>td {
      background: transparent;
    }
  }

  .ant-pagination {
    color: $Pf08Color;

    .ant-pagination-options {
      display: flex;
      align-items: center;

      &>.ant-pagination-options-quick-jumper>input {
        color: $Pf08Color;
        background: rgba(56, 137, 255, 0.1);
        border: 1px solid rgba(0, 137, 255, 0.2);
        border-radius: 2px;
      }

      &>.ant-pagination-options-size-changer {
        height: 32px;

        & .ant-select-dropdown {
          background: linear-gradient(89deg, rgba(14, 46, 75, 0.6) 0%, rgba(9, 21, 42, 0.6) 100%);
          border: 1px solid rgba(0, 137, 255, 0.2);
          border-radius: 2px;

          & .ant-select-item {
            color: $Pf08Color;
          }

          & .ant-select-item.ant-select-item-option-active,
          .ant-select-item.ant-select-item-option-selected {

            &>.ant-select-item-option-content {
              color: $Pf08Color;
              background: transparent;
            }
          }
        }

        &>.ant-select-arrow {
          color: $Pf08Color;
        }

        &>.ant-select-selector {
          height: 100%;
          background: rgba(56, 137, 255, 0.1);
          border: 1px solid rgba(0, 137, 255, 0.2);
          border-radius: 2px;

          &>.ant-select-selection-item {
            line-height: 30px;
            color: $Pf08Color;
          }
        }
      }
    }

    .ant-pagination-item {
      background: rgba(56, 137, 255, 0.1);
      border: 1px solid rgba(0, 137, 255, 0.2);
      border-radius: 2px;

      &>a {
        color: $Pf08Color;
      }
    }

    .ant-pagination-item.ant-pagination-item-active>a {
      color: $P2cColor;
    }

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      line-height: 30px;

      &>a>.ant-pagination-item-container {
        color: $Pf08Color;
        background: rgba(56, 137, 255, 0.1);
        border: 1px solid rgba(0, 137, 255, 0.2);
        border-radius: 2px;

        &>.ant-pagination-item-ellipsis {
          color: $Pf08Color;
        }
      }

      &>button {
        color: $Pf08Color;
        background: rgba(56, 137, 255, 0.1);
        border: 1px solid rgba(0, 137, 255, 0.2);
        border-radius: 2px;
      }
    }

    .ant-pagination-item-link,
    .ant-pagination-item {
      .ant-pagination-item-active>a {
        color: $P2cColor;
      }

      .ant-pagination-item:not(.ant-pagination-item-active) {
        &>a {
          color: $Pf08Color;
        }

        background: rgba(56, 137, 255, 0.1);
        border: 1px solid rgba(0, 137, 255, 0.2);
        border-radius: 2px;
      }
    }

  }
}

.ant-table-wrapper {
  .ant-table {
    scrollbar-color: auto !important;
  }
}

.global-selector {
  &.ant-select.datart-daping-selector-dark {
    text-align: center;

    >.ant-select-selector .ant-select-selection-item {
      color: $P2cColor;
    }

    .ant-select-arrow {
      color: $P2cColor;
    }
  }

  &.ant-select {
    >.ant-select-selector {
      background: transparent;
      border: 1px solid rgba(56, 225, 255, 0.5);
      border-radius: 2px;

      .ant-select-selection-item {
        /* stylelint-disable-line */
        color: #fefefe;
      }

      .ant-select-selection-placeholder {
        color: $INPUT_PLACEHOLDER;
      }
    }

    >.ant-select-arrow {
      /* stylelint-disable-line */
      color: $INPUT_PLACEHOLDER;
    }

    >.ant-select-clear {
      color: $INPUT_PLACEHOLDER;
      background-color: rgba(76, 76, 76);
    }
  }

  &.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-arrow {
    opacity: .5;
  }

  &.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: transparent;

    .ant-select-selection-item {
      opacity: .5;
    }
  }
}

.ant-select-dropdown {
  background: linear-gradient(89deg, rgba(14, 46, 75, 0.97) 0%, rgba(9, 21, 42, 0.97) 100%);
  border-radius: 0px;

  .ant-select-item {
    color: $Pf08Color !important;
  }

  .ant-select-item-option-selected,
  .ant-select-item-option-active {
    background-color: rgba(44, 214, 247, 0.1) !important;
  }

  .ant-empty-description {
    color: $Pf08Color;
  }
}

/* todo(tl) 这里是统计汇总 透视表 行列切换 popover 样式 start */
.statistical-analysis-popover {
  padding: 16px;

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    background: linear-gradient(122.39deg, #0E1D37 0%, #0C1628 100%);
    border: 1px solid #1F5284;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.3);
  }

  .ant-popover-inner-content {
    width: 800px;
    height: 645px;
    padding: 0;
  }

  .antv-s2-switcher-content-header {
    padding: 9px 24px;
    margin-bottom: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    color: #2CD6F7;
    border-bottom: 1px solid #1F5284;
  }

  .antv-s2-switcher-content-main {
    grid-gap: 0;
    height: 543px;
    margin-bottom: 0;

    .antv-s2-switcher-dimension {
      Width: 320px;
      Height: 234px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      color: #2CD6F7;

      .normal-item,
      .checkable-item {
        color: #FEFEFE;
        background: #0D1C38;
      }

      &:nth-child(1) {
        margin: 20px 0 0 24px;

      }

      &:nth-child(2) {
        margin: 0 0 0 24px;

      }

      &:nth-child(3) {
        float: right;
        width: 407px;
        height: 495px;
        margin: 20px 24px 0 0;

        .ant-checkbox-inner {
          background: rgba(51, 107, 252, 0.1);
          border: 1px solid $P2cColor;

          &::after {
            color: $P2cColor;
            border-color: $P2cColor;
          }
        }
      }

      .antv-s2-switcher-dimension-items {
        height: calc(100% - 28px);
        max-height: none;
        background: #0E2842;
        border: 1px solid #1F5284;
      }
    }

    .long-dimension {
      padding-left: 24px;
      margin-left: 0;
      border-left: 1px solid #1F5284;
    }
  }

  .antv-s2-switcher-content-footer {
    height: 60px;
    padding: 0 24px;
    border-top: 1px solid #1F5284;

    .antv-s2-switcher-content-footer-reset-button {
      .anticon-reload {
        display: none;
      }

      span {
        margin-left: 0;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        color: $P2cColor;
      }
    }

    .antv-s2-switcher-content-footer-reset-button[disabled] span {
      color: #979797;
    }

    .antv-s2-switcher-content-footer-actions {
      .ant-btn {
        width: 52px;
        height: 28px;
        padding: 0;
        background: rgba(51, 107, 252, 0.1);
        border: 1px solid rgba(44, 214, 247, 0.6);
        border-radius: 2px;

        span {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          color: $P2cColor;
        }
      }

      .ant-btn-primary {
        background: #3D7FFF;
        border: none;

        span {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          color: #FFF;
        }

        &[disabled] {
          color: #DEDEDE;
          background: #6F91D3;
        }
      }
    }
  }

  .antv-s2-switcher-dimension-items::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  .antv-s2-switcher-dimension-items::-webkit-scrollbar-thumb {
    background: rgba(68, 225, 226, 0.8);
    border: none;
    border-radius: 4px;

    &:hover {
      background: rgba(68, 225, 226, 0.8);
    }
  }
}

/* todo(tl) 这里是统计汇总 透视表 行列切换 popover 样式 end */

.datart-daping-geo-controller {
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    background: transparent;

    .ant-popover-inner-content {
      padding: 0;

      .border-box-content {
        padding: 8px;

        .ant-divider {
          height: 2px;
          margin: 8px 0;
          border-image: linear-gradient(90deg, rgba(2, 15, 47, 1), rgba(0, 132, 255, 0.6)) 1 1;
          opacity: 0.7;
        }
      }
    }
  }
}

.global-time-picker {
  height: 32px;
  background: transparent !important;
  border: none;
  border-bottom: 2px solid transparent;
  border-radius: 2px;
  box-shadow: none !important;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    border: transparent;
    box-shadow: none !important;
    border-bottom: 2px solid $P38fColor;
  }

  .ant-picker-separator,
  .ant-picker-suffix {
    color: rgba(254, 254, 254, 0.4);
    background-color: transparent;
  }

  .ant-picker-clear {
    color: rgba(254, 254, 254, 0.4);
    background-color: rgba(76, 76, 76);
  }

  .ant-picker-input input {
    color: #fefefe;
  }

  .ant-picker-input input::placeholder {
    color: rgba(254, 254, 254, 0.4);
  }

  .ant-picker-outlined {
    &:focus-within {
      background-color: #081224 !important;
    }
  }
}

.global-time-picker-popup {
  .ant-picker-range-arrow {
    visibility: hidden;
  }

  .ant-picker-panel-container {
    background: linear-gradient(89deg, #0C1F40 0%, #081224 100%);
    border: 1px solid rgba(56, 225, 255, 0.2);
    backdrop-filter: blur(10px);

    .ant-picker-panel {
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);

      .ant-picker-header {
        color: rgba(255, 255, 255, 0.8);
        border-bottom: 1px solid rgba(255, 255, 255, 0.12);

        .ant-picker-header-super-prev-btn,
        .ant-picker-header-super-next-btn,
        .ant-picker-header-prev-btn,
        .ant-picker-header-next-btn {
          color: rgba(255, 255, 255, 0.25);

          &:hover {
            color: $P2cColor;
          }
        }
      }

      .ant-picker-content th {
        color: rgba(255, 255, 255, 0.8);
      }

      .ant-picker-content td {
        color: rgba(255, 255, 255, 0.25);
        text-shadow: 0px 8px 23px rgba(0, 0, 0, 0.5);
      }

      .ant-picker-cell-today {
        .ant-picker-cell-inner {
          color: $P2cColor;
          background: rgba(56, 137, 255, 0.1);
          box-shadow: 0px 8px 23px 0px rgba(0, 0, 0, 0.5);

          &:before {
            border: 1px solid rgba(0, 137, 255, 0.2);
          }
        }
      }

      .ant-picker-cell-in-range {
        & .ant-picker-cell-inner {
          color: rgba(255, 255, 255, 0.8);
        }

        &:before {
          background: rgba(56, 137, 255, 0.2);
        }
      }

      .ant-picker-cell-range-start:before {
        left: 80%;
      }

      .ant-picker-cell-range-end:before {
        right: 80%;
      }

      .ant-picker-cell-range-hover-start .ant-picker-cell-inner:after {
        opacity: .2;
      }

      .ant-picker-cell-range-start,
      .ant-picker-cell-range-end,
      .ant-picker-cell-hover {
        &:before {
          background: rgba(56, 137, 255, 0.2);
        }

        & .ant-picker-cell-inner {
          color: $P2cColor;
          background: rgba(56, 137, 255, 0.1);
          border: 1px solid rgba(0, 137, 255, 0.6);
          border-radius: 2px;
          box-shadow: 0px 8px 23px 0px rgba(0, 0, 0, 0.5);
        }
      }

      &.ant-picker-panel-has-range.ant-picker-panel-has-range-hover {
        .ant-picker-body {
          .ant-picker-cell:hover {
            :before {
              background: rgba(56, 137, 255, 0.2);
            }

            :after {
              background: rgba(56, 137, 255, 0.2);
            }

            .ant-picker-cell-inner {
              background: rgba(56, 137, 255, 0.2);

              :before {
                background: rgba(56, 137, 255, 0.2);
              }

              :after {
                background: rgba(56, 137, 255, 0.2);
              }
            }
          }

          .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end {
            .ant-picker-cell-inner {
              background: rgba(56, 137, 255, 0.2);

              :before {
                background: rgba(56, 137, 255, 0.2);
              }

              :after {
                background: rgba(56, 137, 255, 0.2);
              }
            }
          }
        }
      }

      .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) {
        .ant-picker-cell-inner {
          color: rgba(255, 255, 255, 0.8);
          background: transparent;
        }
      }
    }
  }
}

:where(.ess-dev-only-do-not-override-1r287do) .ant-picker-outlined:focus,
:where(.css-devonly-do-not-override-1r287do) .ant-picker-outlined:focus-within {
  background-color: red;
}

.global-pagination {
  position: absolute;
  right: 24px;
  bottom: 20px;

  .ant-pagination-total-text {
    color: $PfffColor;
  }

  .ant-pagination-item {
    color: $PfffColor;
    background-color: rgba(56, 137, 255, 0.1);
    border-radius: 0px;
    border: 1px solid rgba(0, 137, 255, 0.2);

    a {
      color: $PfffColor;
    }
  }

  .ant-pagination-item-ellipsis {
    color: $PfffColor !important;
  }

  .ant-pagination-item-link {
    color: $PfffColor !important;
  }

  .antv-s2-pagination-count {
    display: none;
  }

  .ant-select-selector {
    background-color: rgba(56, 137, 255, 0.1) !important;
    border: 1px solid rgba(0, 137, 255, 0.2) !important;
    border-radius: 0px;
    color: $PfffColor;
    cursor: pointer !important;
  }

  .ant-select-arrow {
    color: $PfffColor;
  }

  .ant-pagination-options {
    color: $PfffColor;

    input {
      background-color: rgba(56, 137, 255, 0.1) !important;
      border: 1px solid rgba(0, 137, 255, 0.2);
      border-radius: 0px;
      color: $PfffColor;
    }
  }

  .ant-pagination-item-active {
    a {
      color: $P2c08Color;
    }
  }
}

.global-tooltip {
  max-width: none;
  padding: 10px !important;

  .tooltip-title {
    font-weight: 700;
    color: $P2cColor;
    // margin-top: 10px;
  }

  .tooltip-detail {
    color: $P2cColor;
  }

  .ant-tooltip-inner {
    padding: 0 !important;
  }

  .ant-tooltip-arrow {
    display: none;
  }
}

.ant-popover-inner {
  width: 300px;
  font-size: 14px;
  color: $PfffColor !important;
  background: linear-gradient(180deg, #152b46 0%, #061a32 100%);
  border: 1px solid rgba(31, 82, 132, 1);
  border-radius: 0px !important;

  .ant-popover-title {
    font-size: 14px;
    color: $PfffColor !important;
    border-bottom: 1px solid rgba(44, 214, 247, 0.1);
  }

  .ant-popover-inner-content {
    font-size: 14px;
    color: $PfffColor !important;

    .ant-progress {
      line-height: 16px !important;
    }

    .ant-list-item-meta-description {
      line-height: 16px !important;
    }

    .ant-list-item {
      line-height: 16px !important;
    }

    .ant-list-item-meta-avatar {
      /* margin-top: 5px; */
    }

    .ant-empty-description {
      color: $PfffColor !important;
    }

    .ant-progress-inner {
      height: 4px;
    }

    .ant-list-split .ant-list-item {
      border-bottom: 1px solid rgba(44, 214, 247, 0.1);
    }
  }
}

.ant-tree-treenode-disabled {
  .ant-tree-checkbox-inner {
    background-color: #154969 !important;
    border-color: #176287 !important;
  }
}

:where(.css-dev-only-do-not-override-1r287do).ant-tree .ant-tree-checkbox-disabled .ant-tree-checkbox-inner:after {
  border-color: #D7DDE5;
}

.global-cascader {
  color: #fefefe;
  background: none !important;
  min-height: 30px;
  border: 1px solid rgba(56, 225, 255, 0.5);
  border-radius: 0%;

  &::placeholder {
    color: rgba(254, 254, 254, 0.4) !important;
  }

  .ant-select-selection-placeholder {
    color: rgba(254, 254, 254, 0.4) !important;
  }

  &.ant-input-focused,
  &.ant-input:focus,
  &.ant-input:hover {
    border-color: #4096ff;
    border-radius: 0%;
  }

  .ant-select-selector {
    background-color: rgba(56, 137, 255, 0.1) !important;
    border-radius: 0px;
    color: $PfffColor;
    cursor: pointer !important;
  }

  .ant-select-selection-item {
    color: #fefefe !important;
  }

  &.ant-input[disabled] {
    color: #8791A4;
    background: rgba(56, 137, 255, 0.04) !important;
    border-color: rgba(6, 59, 165, 0.274);
  }
}

.ant-cascader-menu-item {
  color: $Pf08Color;

  &:hover {
    background: rgba(44, 214, 247, 0.1) !important;
    color: $P2cColor !important;
  }
}

.ant-cascader-menu-item-active {
  background-color: rgba(44, 214, 247, 0.1) !important;
  font-weight: 400 !important;
  color: $P2cColor !important;
}

.ant-cascader-menu-item-expand-icon {
  color: #8791A4 !important;
}

.global-radio-group-card {

  .ant-radio-button-wrapper {
    border-radius: 0px !important;
    background: #3889FF1A;
    border: 1px solid #3889FF99;
    color: #FFFFFF80 !important;

    &:not(:first-child) {
      &::before {
        background-color: #3889FF99;
      }
    }
  }

  .ant-radio-button-wrapper-checked {
    color: $PfffColor !important;
    background: rgba(56, 137, 255, 1) !important;
  }
}

.years-filter {
  position: relative;

  .anticon-swap-right {
    color: #DDD;
    position: absolute;
    left: 100px;
  }
}

.ant-checkbox {
  span {
    color: $PfffColor;
  }

  &:hover {
    .ant-checkbox-inner {
      border: 1px solid $P2c08Color;
    }
  }

  .ant-checkbox-inner {
    background: #132943;
    border: 1px solid #1e4683;
  }
}

.ant-checkbox-wrapper {
  &:hover {
    .ant-checkbox-inner {
      border: 1px solid rgb(44, 214, 247) !important;
    }
  }
}

.ant-checkbox-wrapper-checked {

  &:hover {
    .ant-checkbox-inner {
      background-color: #6FCDEE !important;
    }
  }
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    background: $P2cColor;
    border: 1px solid $P2cColor;
  }
}

.ant-checkbox-wrapper-disabled {
  .ant-checkbox-inner {
    background-color: #2CD6F74D;
    border: 1px solid #2CD6F74D;

    &:after {
      border-color: #FFFFFF99;
    }
  }

  &:hover {
    .ant-checkbox-inner {
      border: 1px solid #2CD6F74D !important;
      background-color: #2CD6F74D !important;
    }
  }

  span {
    color: #FFFFFF99;
  }
}

.ant-checkbox-indeterminate {
  .ant-checkbox-inner {
    background: $P2cColor;
    border: 1px solid $P2cColor;

    &:after {
      border-color: #FFFFFF99;
      background: $PfffColor;
    }
  }

  &:hover {
    .ant-checkbox-inner {
      border: 1px solid $P2cColor !important;
      background: $P2cColor !important;
    }
  }
}