.simple-verify {
  position: relative;
  box-sizing: border-box;
  line-height: 1;
  user-select: none;

  .verify-tips {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 14px;
    widows: 100%;
    color: rgba(255, 255, 255, 0.45);
    pointer-events: none;
  }

  .verify-box {
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    overflow: hidden;
  }

  .veriry-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.1s linear, transform 0.3s ease;
  }

  .verify-bar {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 100%;
    cursor: pointer;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background: rgba(56, 225, 255, 0.8);
      border-radius: 2px;
    }
  }

  .verify-success-tips {
    position: absolute;
    top: -1px;
    left: -1px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.1s linear;
  }
}