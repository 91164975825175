.assistant-decision {

    .chat-container {
        display: flex;
        justify-content: end;
        align-items: end;

        .ai-img {
            width: 80px;
            height: 80px;
        }
    }
}