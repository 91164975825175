.file-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;

    .file-title {
        font-size: 26px;
        text-align: center;
        line-height: 40px;
        color: black !important;
        padding-bottom: 40px;
        margin-top: 100px;
    }

    .content {
        font-size: 22px;
        color: black !important;
        line-height: 40px;
        text-indent: 2em;
        padding: 0 100px;

        .content-text {
            font-size: 18px;
            color: black !important;

            .bold {
                font-size: 18px;
                font-weight: 600;
            }
        }

        .content-subtitle {
            font-size: 20px;
            font-weight: 700;
            color: black !important;
        }
    }

    .footer {
        text-align: right;
        font-size: 18px;
        color: black !important;
        line-height: 40px;
        padding: 0 100px;
        padding-bottom: 100px;

        .content-text {
            font-size: 18px;
            color: black !important;
        }
    }

    .teble-box {
        margin: 0 100px;
        border: 1px solid black;

        table {
            width: 100%;
            border-collapse: collapse !important;
        }

        th,
        td {
            padding: 8px;
            text-align: left;
            color: black;
        }

        td {
            border-top: 1px solid black;

        }

        .borderleft {
            border-left: 1px solid black;
        }
    }

}

.width800 {
    width: 800px;
}