$loginColor: #38e1ff;

.login {
  width: 100%;
  height: 100%;
  background-size: cover;

  .login-header {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    height: 50px;
    margin-top: -25px;

    h1 {
      // padding: 0 38px 0 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-size: 48px;
      font-weight: 600;
      line-height: 67px;
      color: #38e1ff;
      text-shadow: 0px 2px 8px rgba(0, 0, 0, 0.36);
    }

    div {
      color: red;
      text-align: center;
    }

    .env {
      font-size: 40px;
    }
  }

  .login-content {
    z-index: 3;

    .ant-tabs {
      position: absolute;
      top: 28%;
      right: 4%;
      width: 460px;
      height: 374px;
      background: linear-gradient(89deg,
          rgba(0, 35, 128, 0.58) 0%,
          rgba(9, 21, 42, 0.82) 100%);
      border: 2px solid rgba(56, 225, 255, 0.5);
      border-radius: 4px;
      backdrop-filter: blur(10px);
      z-index: 2;

      .ant-tabs-nav-list {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
      }

      .ant-tabs-tab+.ant-tabs-tab {
        margin: 0;
      }

      .ant-tabs-nav {
        height: 58px;
        margin-bottom: 38px;
        border: 1px solid rgba(56, 225, 255, 0.23);

        .ant-tabs-tab {
          font-size: 16px;
          font-weight: bold;
          color: rgba(255, 255, 255, 0.8);

          .ant-tabs-tab-btn {
            font-size: 16px;
          }

          .ant-tabs-tab-btn:active,
          .ant-tabs-tab-btn:focus,
          .ant-tabs-tab-remove:active,
          .ant-tabs-tab-remove:focus {
            font-size: 20px;
            color: $loginColor;
            transition: all .3s;
          }

          &.ant-tabs-tab-active .ant-tabs-tab-btn {
            font-size: 20px;
            color: $loginColor;
          }

          .ant-tabs-tab:hover {
            color: $loginColor;
          }
        }
      }

      .ant-tabs-ink-bar {
        background: $loginColor;
      }

      .ant-tabs-nav:before {
        border: none;
      }

      .ant-tabs-content-holder {
        padding: 0 28px;
      }
    }
  }

  .login-footer {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 30px;
    font-size: 14px;
    right: 80px;
    color: rgb(225, 227, 234);
    z-index: 1;

    .filing-text {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      text-align: right;

      .filing-text-phone {
        font-size: 20px;
        font-weight: 700;
      }
    }

    img {
      width: 80px;
      margin-left: 20px;
    }
  }

  .ant-input-suffix {
    span {
      font-size: 14px;
      cursor: pointer;
      color: rgb(56, 225, 255);
      ;
    }
  }

  .ant-btn-primary {
    color: #fff;
    background: linear-gradient(180deg, #0095e3 0%, #004fb7 92%, #0038a9 100%);
    width: 100%;
    border-radius: 0px;
    border: none;

    &.ant-btn-primary[disabled] {
      color: #fff;
      text-shadow: none;
      background: rgba(56, 225, 255, 0.36);
      border: none;
      box-shadow: none;
    }
  }

  .ant-form-item-explain-error {
    font-size: 14px;
  }

  .login-btn {
    margin-top: 40px;

    .ant-btn {
      span {
        font-size: 16px;
      }
    }
  }

  .ant-input-suffix {
    svg {
      fill: rgba(255, 255, 255, 0.8);
    }
  }

  .ant-input {
    background: transparent;
  }
}

:where(.css-dev-only-do-not-override-mzwlov).ant-tabs .ant-tabs-tab-btn {
  outline: none;
  transition: all 0s linear;
}