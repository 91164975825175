@import 'static/_variables.scss';

* {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 14px;
  box-sizing: border-box;
}

ol,
ul,
dl,
dd,
dt,
p {
  margin: 0;
}

html,
body {
  // height: auto;
  height: 100%;
  font-family: -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", SimSun, sans-serif;
  min-height: 100%;
  width: 100%;
  background-color: transparent !important;
}

#app {
  height: 100%;
  scroll-behavior: smooth;
}

#root {
  height: 100%;
  width: 100%;
}

/* 清除浮动 */
.clearFloat:after {
  content: "";
  display: block;
  width: 100%;
  clear: both;
}

.required-before::before {
  content: "* ";
  color: red;
  margin-left: -10px;
}

.scroll-div::-webkit-scrollbar-button {
  display: none;
}

.word-break-break-all {
  word-break: break-all;
}