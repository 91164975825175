@import 'static/_variables.scss';

.insurance-panel-filter {
    width: 118px;
    height: 36px;
    padding: 0 10px;
    margin-right: 10px;
    font-size: 14px;
    line-height: 36px;
    color: $PfffColor;
    cursor: pointer;
    padding: 2px 15px;

    .ant-dropdown-trigger-dis {
        cursor: no-drop;
        opacity: 0.5;
    }
}

.insurance-dropdown {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-space {
        display: flex;
        justify-content: space-between;

        .ant-space-item {
            color: $PfffColor;
            height: 36px;
        }
    }
}

.insurance-dropdown-content {
    width: 500px;
    padding: 10px;
    background: linear-gradient(89deg, rgba(14, 46, 75, 0.97) 0%, rgba(9, 21, 42, 0.97) 100%);
    border: 1px solid rgba(31, 82, 132, 1);

    .insurance-col {
        margin-bottom: 10px;

        .insurance-btn {
            padding: 5px 5px;
            width: 150px;
            background-color: rgba(56, 137, 255, 0.1);
            border-image-source: linear-gradient(to left, rgba(56, 255, 255, 0.1), rgba(56, 225, 255, 1), rgba(56, 255, 255, 0.1));
            border-image-slice: 1 0 0 0;
            color: $PfffColor;
            border-top: 1px solid $P2cColor;
            border-right: 1px solid $P2cColor;
            border-bottom: 1px solid $P2cColor;
            border-left: 1px solid $P2cColor;
            border-radius: 2px;

            &:active,
            &:hover,
            &:focus {
                border-image-source: unset !important;
                border-image-slice: 1 0 0 0;
                color: $P2cColor;
                cursor: pointer;
            }
        }
    }
}

.insurance-active {
    border-image-source: unset !important;
    border-image-slice: 1 0 0 0 !important;
    color: $P2cColor !important;
    cursor: pointer;
}

.insurance-dis {
    .insurance-ellipsis {
        color: $INPUT_PLACEHOLDER;
    }

    svg {
        color: $INPUT_PLACEHOLDER;
    }
}

.insurance-ellipsis {
    display: inline-block;
    width: 80px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.region-dropdown {
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-space {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .ant-space-item {
            color: $PfffColor;
        }
    }
}

.region-dis {
    .region-ellipsis {
        color: $INPUT_PLACEHOLDER;
    }

    svg {
        color: $INPUT_PLACEHOLDER;
    }
}

.region-ellipsis {
    display: inline-block;
    width: 80px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.region-dropdown-content {
    width: 600px;
    height: 500px;
    padding: 10px;
    background: linear-gradient(89deg, rgba(14, 46, 75, 0.97) 0%, rgba(9, 21, 42, 0.97) 100%);
    border: 1px solid rgba(31, 82, 132, 1);

    .region-col {
        margin-bottom: 10px;
        height: 480px;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0 20px;

        .region-title {
            color: $P2cColor;
            margin-bottom: 16px;
            font-size: 16px;
            border-bottom: 1px solid transparent;
            border-image: linear-gradient(270deg, rgba(2, 15, 47, 0), rgba(0, 132, 255, 0.6)) 1 1;
            padding: 10px 0;
        }

        .region-box {
            margin-bottom: 10px;

            .region-btn {
                padding: 5px 5px;
                width: 150px;
                background-color: rgba(56, 137, 255, 0.1);
                border-image-source: linear-gradient(to left, rgba(56, 255, 255, 0.1), rgba(56, 225, 255, 1), rgba(56, 255, 255, 0.1));
                border-image-slice: 1 0 0 0;
                color: $PfffColor;
                border-top: 1px solid $P2cColor;
                border-right: 1px solid $P2cColor;
                border-bottom: 1px solid $P2cColor;
                border-left: 1px solid $P2cColor;
                border-radius: 2px;

                &:active,
                &:hover,
                &:focus {
                    border-image-source: unset !important;
                    border-image-slice: 1 0 0 0;
                    color: $P2cColor;
                    cursor: pointer;
                }
            }
        }

    }
}

.region-active {
    border-image-source: unset !important;
    border-image-slice: 1 0 0 0 !important;
    color: $P2cColor !important;
    cursor: pointer;
}

.ant-picker-disabled {
    border: 0px !important;

    .ant-picker-input input {
        color: $INPUT_PLACEHOLDER !important;
    }
}

.years-filter {
    background-size: cover;
    width: 230px;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;

    .years-filter-time-picker {
        width: '50%';
        height: '34px';
        display: 'flex';
        justify-content: 'center';
        align-items: 'center';
    }

    .ant-picker-focused {
        border: transparent;
        box-shadow: none !important;
        border-bottom: 2px solid $P38fColor !important;
    }
}